<template>
	<div>
		<h2>{{ $t("tiers.menu_contacts") }}</h2>
		<Contact :tiers_id="tiers_id" />
		<h2>{{ $t("tiers.other_tiers_related") }}</h2>
		<div class="box">
			<CustomTable
				id_table="tiers_relations"
				:items="filtredTiersRelations"
				:busy.sync="table_busy"
				:rawColumns="rawColumnNames"
				primaryKey="tiersrelations_id"
				:hrefsRoutes="config_table_hrefs"
			>
			</CustomTable>
		</div>

		<ModalTiersSecondaire ref="modal-add-tiers-secondaire" :tiers_id="tiers_id" @submit="reload"></ModalTiersSecondaire>
	</div>
</template>

<script type="text/javascript">
	import ContactMixin from '@/mixins/Contact.js'
	import FonctionMixin from '@/mixins/Fonction.js'
	import Navigation from '@/mixins/Navigation.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "TiersContact",
		mixins: [ContactMixin, FonctionMixin, Navigation, TiersMixin],
		props: ['tiers_id'],
		data() {
			return {
				tiers_relations: null,
				table_busy: true,
				tiersrelations_id: null,
				rawColumnNames: ['contact_phones_number', 'tiers_fonction'],
				config_table_hrefs: {
					'tiersrelations_to.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'tiersrelations_to.tiers_id'
						}
					}
				},
				events_tab: {
                    'TableAction::goToAddRelations': () => {
						this.addOrEdit()
                    },
					'TableAction::goToEditRelations': (tiers_relations) => {
						this.addOrEdit(tiers_relations)
                    },
					'TableAction::goToDeleteRelations': (tiersrelations_ids) => {
                        this.deleteRelations(tiersrelations_ids)
                    }
                }
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				
				this.tiers_relations = await this.loadTiersRelations(this.tiers_id)
				this.table_busy = false
			},
			async addOrEdit(tiers_relations) {
				let params = null
				if(tiers_relations) {
					this.$refs['modal-add-tiers-secondaire'].open(null, null, tiers_relations)
				}
				else {
					this.$refs['modal-add-tiers-secondaire'].open()
				}
			},
			reload() {
				this.table_busy = true
                this.init_component()
            },
			deleteRelations(tiersrelations_ids) {
				this.invalidTiersRelations(tiersrelations_ids)
				this.reload()
			}
		},
		computed: {
			filtredTiersRelations: function() {
				let tiersRelationsFormated = []
				if(!this.tiers_relations) {
					return []
				}

				const copy_temp = this.deppCloneObj(this.tiers_relations)
				for(let i = 0; i < copy_temp.length; i++) {
					let temp = {}
					if(this.tiers_relations[i].contact) {
						temp = {...copy_temp[i], ...copy_temp[i].contact.contact}
						temp.contact_phones_number = this.getArrayObjProperty(temp.phones, "phone_combine").join("<br>")
					}
					else {
						temp = {...copy_temp[i]}
						temp.contact_phones_number = null
					}
					temp.tiers_fonction = temp.fonction ? this.getTrad(temp.fonction.fonction_label) : ''
					tiersRelationsFormated.push(temp)
				}

				return tiersRelationsFormated
			},
		},
		components: {
			Contact : () => import('@/components/Contact/Contact'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ModalTiersSecondaire: () => import('@/components/Horse/ModalTiersSecondaire')
		}
	}
</script>
